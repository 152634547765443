/* 22. contact section */
.form-control {
    border-radius: 5px;
    padding: 0 15px 2px;
    height: 40px;
    margin-bottom: 15px;
    border: 1px solid $border-color;
    font-family: $secondary-font;
    font-weight: 600;
    font-size: 14px;
    color: $text-color;

    &:focus {
        border-color: $primary-color;
        box-shadow: none !important;
    }

    &::placeholder {
        color: $text-color;
    }
}

/* 22.3 google map */
.map {
    position: relative;

    #map_canvas {
        height: 450px;
    }
}